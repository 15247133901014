import React from 'react';

import withGooglePlaces from 'dpl/decorators/withGooglePlaces';
import ShelterResultsResultsList from 'dpl/search/components/ShelterResultsList';

function ShelterResultsListContainer(props) {
  return <ShelterResultsResultsList {...props} />;
}

export default withGooglePlaces(ShelterResultsListContainer);
